export const NavItemsList = [
  {
    id: '1',
    name: "Home",
    path: "/",
  },
  {
    id: '2',
    name: "About",
    path: "/#about-section",
  },
  {
    id: '3',
    name: "Features",
    path: "/#features-section",
  },
  {
    id: '4',
    name: "Join our Community",
    path: "/#join-community-section",
  },
  {
    id: '5',
    name: "Beginners Guide",
    path: "/#beginner-section",
  },
  {
    id: '6',
    name: "Tokenomics",
    path: "/#tokenomics-section",
  },
  {
    id: '7',
    name: "Roadmap",
    path: "/#roadmap-section",
  },
  {
    id: '8',
    name: "Whitepaper",
    path: "/#whitepaper-section",
  },
];
