const TokenomicsDistributionData = [
  {
    id: "1",
    name: "Development Team",
    share: "10%",
  },
  {
    id: "2",
    name: "Presale & ICO",
    share: "20%",
  },
  {
    id: "3",
    name: "Marketing",
    share: "5%",
  },
  {
    id: "4",
    name: "Locked Liquidity",
    share: "70%",
  },
];

const TokenomicsInformationData = [
  {
    id: "1",
    name: "Token name",
    data: "NAYA Token",
  },
  {
    id: "2",
    name: "Token Abbreviation",
    data: "NAYA",
  },
  {
    id: "3",
    name: "Token Supply",
    data: "50 Million",
  },
  {
    id: "4",
    name: "Network",
    data: "BSC (BEP20)",
  },
  {
    id: "5",
    name: "Token Decimal",
    data: "NAYA",
  },
  {
    id: "6",
    name: "Token Address",
    data: "0xD21Db3F27C818920eFd48fD8BAAcA93CE6F989Fd",
  },
];

export { TokenomicsDistributionData, TokenomicsInformationData };
