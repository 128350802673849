import React from "react";
import Button from "../components/Button";
import { Layout } from "../Layouts/Layout";

export default function WhitePaper() {
  return (
    <Layout>
      <div className="container flex flex-column align-center gap-3">
        <h1 className="text-center"> Whitepaper </h1>
        <div className="">
          <iframe
            src="https://drive.google.com/file/d/1HjXOq6qcJX7hUD1wfbGvQr1srop7Rj3U/preview"
            width="640"
            height="480px"
            allow="autoplay"
            title="NAYA Token Whitepaper"
          ></iframe>
        </div>
        <a
          href="https://drive.google.com/file/d/1HjXOq6qcJX7hUD1wfbGvQr1srop7Rj3U/view"
          target="_blank"
          rel="noreferrer"
        >
          <Button> Download Whitepaper</Button>
        </a>
      </div>
    </Layout>
  );
}
