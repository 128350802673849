import React from "react";

const DownloadCard = (props) => {
  const { cardImg } = props;
  return (
    <div className="card DownloadCard">
      <img src={cardImg} alt="" />
    </div>
  );
};

export default DownloadCard;
