import React from "react";
import NayaSmallScreen from "../images/1xNaya.png";
import NayaMediumScreen from "../images/2xNaya.png";
// import NayaLargeScreen from "../images/3xNaya.png";
import ImageTwo from "../images/SuperToroid-Black-Matte.png";
import Button from "./Button";

const Introduction = () => {
  return (
    <section className="section" id="introduction-section">
      <div className="wrapper">
        <div className="container">
          <div className="grid-2">
            <div className="flex flex-column gap-2 talk">
              <div className=" flex flex-column gap-1">
                <p className="attribute">
                  THE OFFICIAL DIGITAL CURRENCY OF RAVEN LIVESTREAM AND AD100
                </p>
                <h1>
                  Creativity <span>& Kindness</span>
                </h1>

                <p className="moreInfo">
                  The digital currency powering the African creator economy and
                  charities.
                </p>
              </div>

              <Button>Buy NAYA</Button>
            </div>

            <div className="illustration">
              <img src={NayaMediumScreen} alt="sectionOneImageOne" srcSet="" />
            </div>
          </div>
        </div>
        <img className="absolute" src={ImageTwo} alt="sectionOneImageTwo" />
      </div>
    </section>
  );
};

export default Introduction;
