import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scripts/fontawesome";

import "./scss/style.scss";
import "./scss/framework.scss";

import { Dashboard } from "./Pages";

ReactDOM.render(
  <React.StrictMode >
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
