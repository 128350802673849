import React from "react";
import FeaturesList from "../Data/FeaturesList";

import FeatureCard from "./common/featureCard";

const Features = () => {
  return (
    <section className="section" id="features-section">
      <div>
        <div className="container">
          <h2 className="section-title">Features</h2>
          <div className="cards">
            {FeaturesList.map((Feature) => (
              <FeatureCard
                cardImg={Feature.image}
                featureTitle={Feature.name}
                featureDesc={Feature.description}
                key={Feature.id}
              ></FeatureCard>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
