import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RavenLogo from "../images/logo-dark.png";

function JoinCommunity() {
  return (
    <section className="section join-community" id="join-community-section">
      <div className="wrapper">
        <div className="inner-wrapper flex flex-column align-center gap-2">
          <h2 className="section-title">Join our Community</h2>
          <p>
            Our community grows stronger every day. Follow our social media
            platforms to get the most up-to-date and accurate information about
            Naya.
          </p>
          <p>
            Click the icons below to join our various groups and become an
            active member of our community.
          </p>
          <div className="social-cards">
            <a
              href="https://twitter.com/NayaToken"
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
            <a
              href="https://t.me/Nayatoken"
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <FontAwesomeIcon icon={["fab", "telegram"]} />
            </a>
            <a
              href="https://discord.gg/CBxSSHvMws"
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <FontAwesomeIcon icon={["fab", "discord"]} />
            </a>
            <a
              href="https://www.ravenlivestream.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <img src={RavenLogo} alt="" />
            </a>
            <a
              href="https://www.instagram.com/nayatoken/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="social-card"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinCommunity;
