import React from "react";
import DownloadCard from "./common/downloadcard";
import GuideContentInfo from "./common/GuideContentInfo";
import TrustWallet from "../images/trustwallet.png";
import MetaMask from "../images/metamask.png";

const BeginnersGuide = () => {
  return (
    <section className="section" id="beginner-section">
      <div className="container">
        <h2 className="section-title">Beginners Guide</h2>
        <p className="section-subtitle">
          Here are a few ways beginners can buy and store NAYA Tokens safely.
        </p>
        <div className="GuideContent">
          <GuideContentInfo
            downloadTitle="Download the Trust Wallet App"
            downloadbody="Trust Wallet is the official crypto wallet of Binance. You can send, receive and store cryptocurrencies and digital assets like NAYA safely. "
            downloadLink="https://trustwallet.com/download-page"
            learnLink="https://www.youtube.com/watch?v=saXustx90Z0&feature=youtu.be"
          />

          <DownloadCard cardImg={TrustWallet} />
        </div>

        <div className="GuideContent">
          <DownloadCard cardImg={MetaMask} />

          <GuideContentInfo
            downloadTitle="Download the Metamask App"
            downloadbody="MetaMask is a cryptocurrency wallet that allows users to access their Ethereum wallet through a browser extension or mobile app, which can then be used to interact with decentralized applications "
            downloadLink="https://metamask.io/download/"
            learnLink="https://www.youtube.com/watch?v=YVgfHZMFFFQ&feature=youtu.be"
          />
        </div>
      </div>
    </section>
  );
};

export default BeginnersGuide;
