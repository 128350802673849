import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Explore from "./Pages/Explore";
import Home from "./Pages/Home";
import WhitePaper from "./Pages/Whitepaper";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/explore" element={<Explore />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/whitepaper" element={<WhitePaper />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
