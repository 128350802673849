import React from "react";
import Logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../components/Button";
import NavList from "../components/NavList";

window.addEventListener("load", () => {
  const menuToggle = document.querySelector(".menu-toggle");
  const menuToggleOpen = menuToggle.querySelector(".open");
  const menuToggleClose = menuToggle.querySelector(".close");
  const nav = document.querySelector("nav");
  const navItems = nav.querySelectorAll("li");
  let navIsActive = false;

  window.onscroll = (e) => {
    e.preventDefault();
    if (navIsActive) {
      dropDownClose();
      navIsActive = false;
    }

    navItems.forEach((navItem) => {
      navItem.onclick = () => {
        dropDownClose();
      };
    });
  };

  function dropDownOpen() {
    nav.classList.add("active");
    menuToggleOpen.classList.remove("active");
    menuToggleClose.classList.add("active");
    console.log("ndj");
    navIsActive = true;
  }

  function dropDownClose() {
    nav.classList.remove("active");
    menuToggleOpen.classList.add("active");
    menuToggleClose.classList.remove("active");

    navIsActive = false;
    console.log("ndj");
  }

  function getNavToggle() {
    if (!navIsActive) {
      dropDownOpen();
    } else dropDownClose();
  }

  menuToggle.addEventListener("click", (e) => {
    e.preventDefault();
    getNavToggle();
  });
});

const Header = () => {
  return (
    <header className="header">
      <div className="container flex justify-between gap-2">
        <a href="/" className="brand flex align-center pr-2">
          <img src={Logo} alt="naya-logo" className="" />
          Naya Token
        </a>

        <nav className="navbar flex">
          <NavList className="flex justify-center"></NavList>
          <a href="/explore">
            <Button type="outline">Explore</Button>
          </a>
        </nav>

        <div className="menu-toggle">
          <div className="open active" onClick={() => {}}>
            <FontAwesomeIcon icon={["fa", "bars"]} />
          </div>
          <div className="close" onClick={() => {}}>
            <FontAwesomeIcon icon={["fa", "times"]} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
