import RealTimeTrading from "../images/realtimetrading.png";
import CustomizableNfts from "../images/customizablenfts.png";
import CharitySupport from "../images/charitysupport.png";
import StartUpCrowdFunding from "../images/startupcrowdfunding.png";
import CreatorPayment from "../images/creatorpayments.png";
import PassiveIncome from "../images/earnpassiveincome.png";
const FeaturesList = [
  {
    id: '1',
    name: "Real Time Trading",
    image: RealTimeTrading,
    description:
      "Buy, sell & swap NAYA on our amazing exchange platform. Trade BTC, ETH, DOGE and LTC",
  },
  {
    id: '2',
    name: "Collectibles & NFTs",
    image: CustomizableNfts,
    description:
      "Create and mint your own NFTs directly on NAYA. Anyone can buy, sell or distribute NFTs and other digital assests directly into your wallet",
  },
  {
    id: '3',
    name: "Charity Support",
    image: CharitySupport,
    description:
      "Donate directly to vetted charities and non-profits making a difference on the African continent",
  },
  {
    id: '4',
    name: "Start-up Crowdfunding",
    image: StartUpCrowdFunding,
    description:
      "Get your idea or start-up funded. NAYA help founders get pre-seed funding from everyday people and investors worldwide.",
  },
  {
    id: '5',
    name: "Creator Payment",
    image: CreatorPayment,
    description:
      "Monetise all forms of content on Raven. Virtual events, YouTube videos, Podcasts and all content. You can receive donations sell ticket with NAYA Token on Raven Livestream",
  },
  {
    id: '6',
    name: "Earn Passive income",
    image: PassiveIncome,
    description:
      "Earn passive income by completing advertising tasks on Ad100.xyz and get paid in NAYA Token",
  },
];

export default FeaturesList;
