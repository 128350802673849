import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../images/naya1.png";
import FloatingImage from "../images/RoundCube-Iridescent.png";
import Button from "./Button";

const WhitePaper = () => {
  return (
    <section className="section whitepaper" id="whitepaper-section">
      <div className="container">
        <h4 className="section-title">Whitepaper</h4>
        <div className="whitepaper-inner">
          <div className="grid-2 gap-2">
            <div className="flex flex-column gap-2 justify-around ">
              <div className="flex gap-2 flex-column">
                <h2>"Your Gateway Into the Blockchain"</h2>
                <p>
                  Naya Token is not your everyday token. It is built with the
                  philosophy of helping people all over the world get access to
                  digital and financial opportunities by creating communities
                  and directing funding to creators, charities, entrepreneurs
                  and everyday people.
                </p>
              </div>


              <Button type="outline" onClick={(e) => {
                e.preventDefault()
                window.open('https://drive.google.com/file/d/1HjXOq6qcJX7hUD1wfbGvQr1srop7Rj3U/view', "_blank")
              }}>Read</Button>

            </div>
            <div className="image">
              <img src={LogoImage} alt="logoimg" />
            </div>
          </div>
          <div className="absolute">
            <img src={FloatingImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
