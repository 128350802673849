import React from "react";

const TokenDistribution = (props) => {
  const { name, share } = props;

  return (
    <div className="card flex-item tokendistribution">
      <div className="before"></div>
      <p>
        {name} {share}
      </p>
      <div className="after"></div>
    </div>
  );
};

export default TokenDistribution;
