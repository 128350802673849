import React from "react";

const About = () => {
  return (
    <section className="section" id="about-section">
      <div className="inner-wrapper flex flex-column gap-1">
        <h2 className="section-title">What is NAYA TOKEN?</h2> <p></p>
        <p>
          NAYA Token is a hyper crypto currency token built on the Binance
          smartchain and the digital currency aimed at helping African creators,
          charities, entreprenuers and everyday people interact with
          decentralised financing. NAYA is your gateway to the possibilities of
          blockchain technology and the official digital currency for
          <a href="/"> Raven Livestream </a> and
          <a href="/"> Ad100.xyz. </a>
        </p>
      </div>
    </section>
  );
};

export default About;
