import React, { useRef, useState } from "react";
import copy from "copy-to-clipboard"

const TokenInformation = (props) => {
  const [copyText, setCopyText] = useState('');

  const handleCopyText = (e) => {
    setCopyText(e.target.value);
  }

  const copyToClipboard = () => {
    copy(content);
    alert(`You have copied "${content}"`);
  }
  const { content, heading } = props;

  return (
    <div className="tokeninformation flex-item">
      <p>{heading}</p>

      <div className="card">
        <div className="before"> </div>
        <p  onClick={copyToClipboard}>{content}</p>
        <div className="after"></div>
      </div>
    </div>
  );
};

export default TokenInformation;
