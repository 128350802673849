import About from "../components/about";
import BeginnersGuide from "../components/beginnersguide";
import CountDownTimer from "../components/CountDownTimer";
import Features from "../components/features";
import Introduction from "../components/introduction";
import PreSaleProgress from "../components/preSaleProgress";
import RoadMap from "../components/roadmap";
import Tokenomics from "../components/tokenomics";
import WhitePaper from "../components/whitepaper";
import JoinCommunity from "../components/joinCommunity";
import { progressBarWidth } from "../Data/Preriquisite";
import { Layout } from "../Layouts/Layout";

function Home() {
  progressBarWidth();

  return (
    <Layout>
      <Introduction />
      <About />
      <Features />
      <JoinCommunity />
      <BeginnersGuide />
      <Tokenomics />
      <PreSaleProgress />
      <CountDownTimer />
      <RoadMap />
      <WhitePaper />
    </Layout>
  );
}

export default Home;
