import React from "react";
import Button from "../Button";

const GuideContentInfo = (props) => {
  const { downloadTitle, downloadbody, downloadLink, learnLink } = props;

  return (
    <div className="GuideContentInfo flex flex-column">
      <h4 className="title">{downloadTitle}</h4>
      <p>{downloadbody}</p>

      <div className="flex mt-1 button-group">
        <Button
          onClick={() => {
            window.open(downloadLink, "_blank");
          }}
        >
          Download
        </Button>
        <Button
          onClick={() => {
            window.open(learnLink, "_blank");
          }}
          type="outline"
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};

export default GuideContentInfo;
