import React from "react";
import {
  softCap,
  hardCap,
  amountRaised,
  NAYACurrency,
  totalToken,
} from "../Data/Preriquisite";

const PreSaleProgress = () => {
  return (
    <section className="section pre-sale-progress-section" id="presale-section">
      <div className="container">
        <h2 className="section-title">Pre-Sale Progress</h2>
        <div className="flex flex-column gap-2">
          <div className="flex justify-between align-center">
            <div>
              <h6>Amount Raised</h6>
              <p className="text-accent">
                <span id="amountRaisedValue">{amountRaised}</span>{" "}
                {NAYACurrency}
              </p>
            </div>
            <div className="text-right">
              <h6>Total Token</h6>
              <p className="text-accent">
                {totalToken} {NAYACurrency}
              </p>
            </div>
          </div>
          <div className="ProgressBar">
            <div className="detect"></div>
          </div>

          <div className="flex justify-between align-center">
            <div>
              <h6 className="text-accent">SOFT CAP</h6>
              <p className="text-accent">
                {softCap} {NAYACurrency}
              </p>
            </div>
            <div className="text-right">
              <h6 className="text-accent">HARD CAP</h6>
              <p className="text-accent">
                {hardCap} {NAYACurrency}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreSaleProgress;
