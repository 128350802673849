import React, { useEffect, useState } from "react";
import Button from "./Button";
import { ExchangeRate, Launchhdate } from "../Data/Preriquisite";

const CountDownTimer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date(Launchhdate) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className="timing">
        <span className="time"> {timeLeft[interval]}</span>
        <span className="label"> {interval}</span>
      </div>
    );
  });

  return (
    <div className="inner-wrapper p-2">
      <div
        className="CountDownTimer flex justify-around"
        id="CountDown-section"
      >
        <div className="box">
          <div className="box-inner">
            <h4 className="title">Pre-sale Pricing</h4>
            <h6 className="price">1 NAYA = {ExchangeRate}</h6>
          </div>
        </div>

        <div className="box">
          <div className="box-inner">
            <h4 className="title">Pre-sale starts in</h4>

            <h6 className="countdown">
              {timerComponents.length ? (
                timerComponents
              ) : (
                <span>Presale Started!</span>
              )}
            </h6>
          </div>
        </div>

        <div className="box">
          <Button>Buy Naya</Button>
        </div>
      </div>
    </div>
  );
};

export default CountDownTimer;
