import NavItem from "./NavItem";

import { NavItemsList } from "../Data/NavItemsList";
const NavList = () => {
  return (
    <ul className="flex justify-center">
      {NavItemsList.map((Nav) => (
        <NavItem nav={Nav} key={`nav-${Nav.id}`}></NavItem>
      ))}
    </ul>
  );
};

export default NavList;
