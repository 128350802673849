import React from "react";
import Logo from "../images/logo.png";
import Button from "../components/Button";
import NavList from "../components/NavList";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container flex gap-1 justify-between flex-wrap component">
        <div className="footer-item">
          <h4>Pages</h4>
          <NavList />
        </div>

        <div className="footer-item">
          <h4>Socials</h4>
          <ul>
            <li>
              <a href="https://twitter.com/NayaToken">Twitter</a>
            </li>
            <li>
              <a href="https://t.me/Nayatoken">Telegram</a>
            </li>
            <li>
              <a href="https://discord.gg/CBxSSHvMws">Discord</a>
            </li>
            <li>
              <a href="https://www.ravenlivestream.com/">Raven Livestream</a>
            </li>
            <li>
              <a href="https://www.instagram.com/nayatoken/">Instagram</a>
            </li>
            <li>
              <a href="/">Facebook</a>
            </li>
          </ul>
        </div>

        <div className="footer-item">
          <h4>Newsletter</h4>
          <input placeholder="Your email address" />
          <Button>Subscribe</Button>
        </div>
      </div>
      <div className="container flex justify-between align-center gap-1 copyright py-0">
        <a className="brand flex align-center" href="/">
          <img src={Logo} alt="naya-logo" className="" />
          NAYA TOKEN
        </a>
        <p>Copyright © 2022 | Designed & Created with love by the NAYA team.</p>
      </div>
    </div>
  );
};

export default Footer;
