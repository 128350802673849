import React from "react";
import { Layout } from "../Layouts/Layout";

export default function Dashboard() {
  return (
    <Layout>
      <h1>Dashboard</h1>
    </Layout>
  );
}
