import React from "react";

const FeatureCard = (props) => {
  const { cardImg, featureTitle, featureDesc } = props;

  return (
    <div className="card FeatureCard">
      <div className="image">
        <img src={cardImg} alt="" />
      </div>
      <h5 className="title">{featureTitle}</h5>
      <p className="content">{featureDesc}</p>
    </div>
  );
};

export default FeatureCard;
