import React from "react";
import { Layout } from "../Layouts/Layout";

export default function Explore() {
  return (
    <Layout>
      <div className="container">
        <h1> Explore page</h1>
      </div>
    </Layout>
  );
}
