import React from "react";
import TokenDistribution from "./common/tokenDistribution";
import TokenInformation from "./common/tokenInformation";
import {
  TokenomicsInformationData,
  TokenomicsDistributionData,
} from "../Data/TokenomicsData";

const Tokenomics = () => {
  return (
    <section className="section" id="tokenomics-section">
      <div className="container">
        <h2 className="section-title">NAYA Tokenomics</h2>
        <div className="tokenomics-grid">
          <div className="grid-item tokenomics_distribution">
            <h4>Token Distribution</h4>
            <div className="flex flex-column">
              {TokenomicsDistributionData.map((TokennomicDistData) => (
                <TokenDistribution
                  key={TokennomicDistData.id}
                  name={TokennomicDistData.name}
                  share={TokennomicDistData.share}
                />
              ))}
            </div>
          </div>

          <div className="grid-item tokenomics_information">
            <h4>Token Information</h4>
            <div className="flex">
              {TokenomicsInformationData.map((TokennomicInfoData) => (
                <TokenInformation
                  key={TokennomicInfoData.id}
                  heading={TokennomicInfoData.name}
                  content={TokennomicInfoData.data}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
