import React from "react";

const RoadMapCard = (props) => {
  const { number, featureTitle, featureDesc } = props;

  return (
    <div className="card text-center RoadMapCard">
      <div className="image">{number}</div>
      <h5 className="title">{featureTitle}</h5>
      <p className="content">{featureDesc}</p>
    </div>
  );
};

export default RoadMapCard;
