const Launchhdate = "02/04/2022"; /* MM / DD / YYYY */
const ExchangeValue = "0.2";
const ExchangeCurrency = "USD";

const NAYACurrency = "NAYA";

const ExchangeRate = `${ExchangeValue}${ExchangeCurrency}`;

const softCap = 10000; /* in NAYA */

const hardCap = 2000000; /* in NAYA */

let amountRaised = 0; /* in NAYA */

const amountRaisedValue = `${amountRaised} NAYA`; /* in NAYA */

const totalToken = 10000000;
const AmoutRaisedRatio = (amountRaised / totalToken) * 100;


function copyText(element) {
  alert("copied");
}

function progressBarWidth() {
  window.addEventListener("load", () => {
    const ProgressBar = document.querySelector(".ProgressBar");
    const ProgressBarDetect = ProgressBar.querySelector(".detect");
    ProgressBarDetect.style.width = `${AmoutRaisedRatio}%`;
  });
}

export {
  Launchhdate,
  ExchangeRate,
  ExchangeCurrency,
  NAYACurrency,
  softCap,
  hardCap,
  totalToken,
  amountRaised,
  progressBarWidth,
};
