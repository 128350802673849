import React from "react";
import Button from "./Button";
import RoadMapCard from "./roadmapCard";

const RoadMap = () => {
  return (
    <section className="section roadmap" id="roadmap-section">
      <div className="container">
        <h2 className="section-title mt-3">Our Roadmap</h2>
        <div className="inner-wrapper flex flex-column gap-1 text-center align-center mb-3">
          <p className="">
            Lets take you briefly through our journey from development to
            launch.
          </p>
        </div>

        <div className="cards">
          <RoadMapCard
            number="1"
            featureTitle="Token & App Development"
            featureDesc="Product Architecture
            | Team Onboarding | ICO website  creation"
          />

          <RoadMapCard
            number="2"
            featureTitle="Whitepaper release & Pre-sale"
            featureDesc="Whitepaper Release, Social Media and Documentation
            | Token pre-sales and ICO. "
          />

          <RoadMapCard
            number="3"
            featureTitle="Launch & Expansion"
            featureDesc="Staking program launch | External audit by Techrate | Cross-chain support for BSC "
          />
        </div>

        <div className="roadmap-btn mt-3">
          <Button >Buy NAYA</Button>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
